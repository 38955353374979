/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable array-callback-return */
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import parse from 'html-react-parser';

// component
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';
import { ReactComponent as ThumbsUpIcon } from 'ASSETS/icon_thumbs_up.svg';
import { ReactComponent as ThumbsDownIcon } from 'ASSETS/icon_thumbs_down.svg';
import { ReactComponent as ArrowDownIcon } from 'ASSETS/icon_arrow_down.svg';
import { ReactComponent as ArrowUpIcon } from 'ASSETS/icon_arrow_up.svg';
import { ReactComponent as CallHistoryIcon } from 'ASSETS/icon_call_history.svg';
import { ReactComponent as AddContactIcon } from 'ASSETS/icon_add_participants.svg';
import { ReactComponent as FileLaunchIcon } from 'ASSETS/icon_launch.svg';
import { ReactComponent as ClearIcon } from 'ASSETS/icon_clear.svg';
import { ReactComponent as CheckmarkIcon } from 'ASSETS/icon_checkmark_green.svg';

// Translation
import { translate } from 'SERVICES/i18n';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';
import './Search.scss';
import * as knowledgeDeliveryClient from 'SERVICES/KnowledgeDelivery/KnowledgeDeliveryClient';
import { getWorkspaceSettings, getAskAndSearchParams } from 'STORE/ClientSettings/ClientSettingsSelector';
import { bindActionCreators } from 'redux';
import { withOrientationChange } from 'react-device-detect';
import { connect } from 'react-redux';
import { getAuthToken } from 'STORE/Auth/AuthSelector';
import Thumbnail from './Thumbnail';
import DocumentFilters from './DocumentFilters';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      searchText: '',
      groupControllers: [translate('search.answer'), translate('search.files'), translate('search.calls'), translate('search.contacts')],
      requestController: null,
      groupedOptions: [{
        entries: [],
        count: 0,
        label: translate('search.answer'),
        name: translate('search.onsightAnswer'),
      }, {
        entries: [],
        filter: knowledgeDeliveryClient.getDefaultWorkspaceFilter(false),
        count: 0,
        label: translate('search.files'),
        name: translate('search.files'),
        searchFunction: this.findFiles.bind(this),
      }, {
        entries: [],
        filter: knowledgeDeliveryClient.getDefaultWorkspaceFilter(true),
        count: 0,
        label: translate('search.calls'),
        name: translate('search.calls'),
        searchFunction: this.findCalls.bind(this),
      }, {
        entries: [],
        count: 0,
        label: translate('search.contacts'),
        name: translate('search.contacts'),
      }],
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Function to handle when user types in search bar
  handleInputChange = (event) => {
    const searchTerm = event.target.value;

    // cancel previous token if available
    const newAbortController = new AbortController();
    if (this.state.requestController) {
      this.state.requestController.abort();
    }

    this.setState(() => ({
      searchText: searchTerm,
      requestController: newAbortController,
    }));

    // send out requests to all configured services
    if (this.props.askAndSearchParams?.azureServices) {
      const promises = [];
      const answers = [];
      this.props.askAndSearchParams.azureServices.forEach((azureService) => {
        if (azureService?.type === 'AzureQuestionAnswer' && azureService?.endpoint && azureService?.subscriptionkey) {
          promises.push(knowledgeDeliveryClient.getAnswer(azureService.endpoint, azureService.subscriptionkey, searchTerm, newAbortController)
            .then((res) => {
              // request could be canceled or any error happened
              if (res === null) { return; }
              console.debug(`Search::handleInputChange(): Get QnA: ${searchTerm} (${res.length})`, azureService.endpoint);
              answers.push(...res);
            })
            .catch((error) => {
              console.error('Search::handleInputChange() getAnswer failed - ', error, azureService.endpoint);
            }));
        }
      });

      // wait for all requests to complete
      Promise.all(promises).then(() => {
        if (this._isMounted) {
          this.setState((prevState) => {
            const newGroupedOptions = prevState.groupedOptions;
            newGroupedOptions.find(
              (x) => x.label === translate('search.answer'),
            ).entries = answers
              .sort((a, b) => b.confidenceScore - a.confidenceScore)
              .map((e) => ({ answer: e.answer, confidenceScore: e.confidenceScore }))
              .filter((e) => e.confidenceScore !== 0);
            newGroupedOptions.count = newGroupedOptions.entries;
            return { groupedOptions: newGroupedOptions };
          }, () => {
            if (this.props.workspaceSettings?.url && this.props.workspaceSettings?.path) {
              this.findCalls(searchTerm, 0, this.state.groupedOptions.find((x) => x.label === translate('search.calls')).filter, newAbortController);
              this.findFiles(searchTerm, 0, this.state.groupedOptions.find((x) => x.label === translate('search.files')).filter, newAbortController);
            }
          });
        }
      });
    }

    knowledgeDeliveryClient.findContacts(searchTerm, this.props.authToken, newAbortController)
      .then((res) => {
        const contacts = res;
        if (this._isMounted) {
          // request could be canceled or any error happened
          if (res === null) { return; }

          this.setState((prevState) => {
            console.debug(`Search::handleInputChange(): Get contacts: ${searchTerm} (${contacts.length})`);
            const newGroupedOptions = prevState.groupedOptions;
            const personalUniqueIds = this.props.personalList.map(
              (item) => item.uniqueId,
            );
            newGroupedOptions.find(
              (x) => x.label === translate('search.contacts'),
            ).entries = contacts.map((e) => ({
              id: e.uniqueId,
              name: e.name,
              address: e.address,
              added: personalUniqueIds.includes(e.uniqueId),
            }));
            newGroupedOptions.count = newGroupedOptions.entries;
            return { groupedOptions: newGroupedOptions };
          });
        }
      })
      .catch((error) => {
        console.error('Search::handleInputChange() findContacts failed - ', error);
      });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleFilterClick = (e) => {
    e.target.classList.toggle('filter-active');

    this.setState((previousState) => {
      previousState.filter = previousState.filter.filter((item) => item !== e.target.textContent.includes);
      if (e.target.classList.contains('filter-active')) {
        previousState.filter.push(e.target.textContent);
      }
      return {
        filter: previousState.filter,
      };
    });
  }

  handleCollapsibleClick(e) {
    const button = e.currentTarget;
    const content = button.parentElement.nextElementSibling;
    [...button.children].forEach((element) => element.toggleAttribute('hidden'));
    button.classList.toggle('active');
    if (!button.classList.contains('active')) {
      content.classList.remove('collapsible-content');
      content.classList.add('collapsible-content-expand');
      content.style.maxHeight = content.scrollHeight + 'px';
    } else {
      content.classList.remove('collapsible-content-expand');
      content.classList.add('collapsible-content');
      content.style.maxHeight = '100px';
    }
  }

  getMatchedFileField(field) {
    if (field === 'ecm:binarytext') return 'Content: ';
    if (field === 'dc:title.fulltext') return 'Title: ';
    if (field === 'ecm:tag') return 'Tag: ';
    if (field === 'dc:description.fulltext') return 'Description: ';
    if (field === 'note:note.fulltext') return 'Note: ';
    if (field === 'file:content.name') return 'Content: ';
    if (field === 'ls-common:ls-metadata-list.full.fulltext') return 'Metadata: ';
    return 'Not Supported';
  }

  findCalls(searchTerm, page, filter, abortController) {
    knowledgeDeliveryClient.findWorkspace(
      this.props.authToken,
      searchTerm,
      page,
      this.props.workspaceSettings,
      abortController,
      filter,
      true,
    )
      .then((res) => {
        // request could be canceled or any error happened
        if (res === null) { return; }

        if (this._isMounted) {
          this.setState(async (prevState) => {
            console.debug(`Search::handleInputChange(): Get workspace Calls: ${searchTerm} (${res.entries.length})`);
            const newGroupedOptions = prevState.groupedOptions;
            const group = newGroupedOptions.find((x) => x.label === translate('search.calls'));
            group.entries = res.entries;
            group.current = res.current;
            group.pages = res.pages;
            group.count = res.count;
            return { groupedOptions: newGroupedOptions };
          });
        }
      })
      .catch((error) => {
        console.error('Search::handleInputChange() findWorkspace failed - ', error);
      });
  }

  findFiles(searchTerm, page, filter, abortController) {
    knowledgeDeliveryClient.findWorkspace(
      this.props.authToken,
      searchTerm,
      page,
      this.props.workspaceSettings,
      abortController,
      filter,
      false,
    )
      .then((res) => {
        // request could be canceled or any error happened
        if (res === null) { return; }

        if (this._isMounted) {
          this.setState(async (prevState) => {
            console.debug(`Search::handleInputChange(): Get workspace Files: ${searchTerm}  (${res.entries.length})`);
            const newGroupedOptions = prevState.groupedOptions;
            const group = newGroupedOptions.find((x) => x.label === translate('search.files'));
            group.entries = res.entries;
            group.current = res.current;
            group.pages = res.pages;
            group.count = res.count;
            return { groupedOptions: newGroupedOptions };
          });
        }
      })
      .catch((error) => {
        console.error('Search::handleInputChange() findWorkspace failed - ', error);
      });
  }

  renderContact(entries) {
    const addSearchedContact = async (entry) => {
      entry.addContactClicked = true;
      this.props.addSearchedContact(entry.id);
      this.setState((prevState) => {
        const newGroupedOptions = prevState.groupedOptions;
        const contacts = newGroupedOptions.find(
          (x) => x.label === translate('search.contacts'),
        ).entries;
        contacts.find((e) => e.id === entry.id).added = true;
        return {
          groupedOptions: newGroupedOptions,
        };
      });
    };
    const call = (entry) => {
      this.props.placeCall(entry.name, entry.address);
    };

    return entries.map((entry) => (
      <div className='item' key={entry.name + entry.address}>
        <CallHistoryIcon className='item-icon call-history-icon' />
        <div className='item-details'>
          <p className='details-major'>{entry.name}</p>
          <p className='details-minor'>{entry.address}</p>
        </div>

        {entry.added ? (
          entry.addContactClicked ? (
            <p className='item-action'>
              <CheckmarkIcon className='checkmark-icon' />
            </p>
          ) : null
        ) : (
          <p className='item-action' onClick={() => addSearchedContact(entry)}>
            <AddContactIcon className='add-contact-icon' />
          </p>
        )}
        <p className='item-action' onClick={() => call(entry)}>
          <CallHistoryIcon className='call-history-icon' />
        </p>
      </div>
    ));
  }

  renderFiles(entries) {
    return entries.map((entry) => (
      <div key={`file-${entry.uniqueId}`}>
        <div className='item'>
          <div className='item-icon'>
            <Thumbnail
              thumbnailFunction={knowledgeDeliveryClient.getWorkspaceThumbnail}
              sessionToken={this.props.authToken}
              doc={entry}
              workspaceSettings={this.props.workspaceSettings}
            />
          </div>
          <div className='item-details'>
            <p className='details-major'>{entry.transcription ? `Call with ${entry.participants}` : entry.name}</p>
            <p className='details-minor'>{`${entry.type} | ${(new Date(entry.lastModified)).toLocaleDateString()} ${(new Date(entry.lastModified)).toLocaleTimeString()}${entry.author ? `| ${entry.author}` : ''}`}</p>
          </div>

          <p className='item-action' onClick={() => window.open(entry.path, '_blank', 'noopener,noreferrer')}>
            <FileLaunchIcon className='file-launch-icon' />
          </p>
        </div>
        <div className='item-expand'>
          {entry.highlights.map((highlight) => (
            <div className='file-search-match' key={`file-${entry.uniqueId}-${entry.highlights.indexOf(highlight)}`}>
              <p className='file-search-match-field'>{this.getMatchedFileField(highlight.field)}</p>
              {highlight.segments.map((segment) => (
                <p className='file-search-match-text' key={`file-${entry.uniqueId}-${entry.highlights.indexOf(highlight)}-${highlight.segments.indexOf(segment)}`}>
                  {parse(`${highlight.segments.indexOf(segment) !== 0 ? '...' : ''}${segment}`)}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    ));
  }

  renderAnswer(entries) {
    return entries.map((entry) => (
      <div key={`answer-${entries.indexOf(entry)}`}>
        <div className='item'>
          <div className='item-details'>
            <p className='details-major'>{`${(entry.confidenceScore * 100).toFixed()}%`}</p>
          </div>
          <ThumbsUpIcon className='item-action thumbs-up-icon' />
          <ThumbsDownIcon className='item-action thumbs-down-icon' />
          <button type='button' className='collapsible item-action active' onClick={this.handleCollapsibleClick}>
            <ArrowDownIcon className='arrowDown arrow-down-icon' />
            <ArrowUpIcon className='arrowUp arrow-up-icon' hidden />
          </button>
        </div>

        <div className='collapsible-content details-minor'>
          <p>{entry.answer}</p>
        </div>
      </div>
    ));
  }

  renderPageButtons(group) {
    if (group.pages !== undefined) {
      const getButton = (index) => (
        <p
          className={`${index === group.current ? 'current-page' : 'other-page'}`}
          key={`page-${index}`}
          type='button'
          onClick={() => group.searchFunction(
            this.state.searchText,
            index, group.filter,
            this.state.abortController,
          )
          }
        >
          {index + 1}
        </p>
      );

      const buttons = [];
      for (let i = 0; i < group.pages; i += 1) {
        if (Math.abs(i - group.current) > 2) {
          if (i === 0) {
            buttons.push(getButton(i));
          }
          if (i === group.pages - 1) {
            buttons.push(getButton(i));
          }
        } else if (Math.abs(i - group.current) === 2 && i !== 0 && i !== group.pages - 1) {
          buttons.push((<div key={i} className='dots'><p>...</p></div>));
        } else {
          buttons.push(getButton(i));
        }
      }
      return (
        <div className='page-buttons'>
          {buttons.map((e) => e)}
        </div>
      );
    }
    return null;
  }

  renderGroup(group, index) {
    if (group.entries.length === 0) return null;

    const isContact = group.label === translate('search.contacts');
    const isFiles = group.label === translate('search.files') || group.label === translate('search.calls');
    const isAnswers = group.label === translate('search.answer');
    const renderFunction = isContact
      ? this.renderContact.bind(this)
      : isFiles
        ? this.renderFiles.bind(this)
        : isAnswers
          ? this.renderAnswer.bind(this)
          : null;

    if (renderFunction == null) return null;
    if (!this.state.groupControllers.includes(group.label)) return null;

    return (
      <div key={group.name}>
        <div className='divider' />
        <div className='group-title'>
          <p>{group.name}</p>
          <div className='pill-button-container'>
            {
              (group.filter ?? [])
                .map((item, filterIndex) => item.used
                  ? this.renderFilterIndicator(index, filterIndex)
                  : null)
            }
          </div>
        </div>
        <div className='group-content'>
          {renderFunction(group.entries)}
        </div>
        {this.renderPageButtons(group)}
      </div>
    );
  }

  renderGroupController(name) {
    const { count, searchFunction } = this.state.groupedOptions.find((e) => e.label === name);
    const isFile = (x) => x === translate('search.files');
    const isCalls = (x) => x === translate('search.calls');
    const hasFilter = (x) => isFile(x) || isCalls(x);

    return (
      <p
        type='button'
        className='pill-button active'
        key={name}
        value={name}
        onClick={(e) => {
          e.target.classList.toggle('active');

          this.setState((previousState) => {
            previousState.groupControllers = previousState.groupControllers.filter((item) => item !== name);
            if (e.target.classList.contains('active')) {
              previousState.groupControllers.push(name);
            }
            return {
              groupControllers: previousState.groupControllers,
            };
          });
        }}
      >
        {`${name}${count === 0 || (!count) ? '' : ` (${count})`}`}

        {hasFilter(name) ? (
          <DocumentFilters
            config={this.state.groupedOptions.find((x) => x.label === name).filter}
            getConfig={() => knowledgeDeliveryClient.getDefaultWorkspaceFilter(isCalls(name))}
            onApply={(filter) => {
              this.setState(async (prevState) => {
                const newGroupedOptions = prevState.groupedOptions;
                const group = newGroupedOptions.find((x) => x.label === name);
                group.filter = filter;

                return { groupedOptions: newGroupedOptions };
              });
              searchFunction(this.state.searchText, 0, filter, this.state.requestController);
            }}
          />
        )
          : null}
      </p>
    );
  }

  renderFilterIndicator(groupIndex, filterIndex) {
    return (
      <p
        type='button'
        className='pill-button active'
        key={`${groupIndex}-${filterIndex}-indicator`}
        onClick={() => {
          this.setState(((previousState) => {
            const { filter } = previousState.groupedOptions[groupIndex];
            const newFilter = knowledgeDeliveryClient.clearFilter(filter, filterIndex);
            previousState.groupedOptions[groupIndex].filter = newFilter;
            previousState.groupedOptions[groupIndex].searchFunction(
              previousState.searchText,
              0,
              newFilter,
              previousState.requestController,
            );
            return {
              groupedOptions: previousState.groupedOptions,
            };
          }));
        }}

      >
        {this.state.groupedOptions[groupIndex].filter[filterIndex].name}

        <ClearIcon className='icon clear-icon' />
      </p>
    );
  }

  render() {
    return (
      <Sidebar className='h-100 overflow-y call-history-sidebar'>
        <Row lg={12} md={8} sm={12} xs={12} className='sidebar-header m-0 p-0 contact-title search-sidebar-header pt-2'>
          <Col lg={12} md={10} sm={8} xs={12} className='d-flex m-0 p-0 align-center justify-content-lg-center justify-content-md-center justify-content-around'>
            <Col className='d-lg-none d-xl-none p-0 ' xs={1} sm={1} lg={1} md={1}>
              <BackIcon
                className='back-icon'
                alt='back'
                role='presentation'
                onClick={this.props.goBack}
              />
            </Col>
            <Col
              className='d-flex justify-content-center justify-content-lg-start history-header ps-3'
              xs={5}
              sm={5}
              md={5}
              lg={8}
            >
              <span className='search-header'>
                {translate('search.searchHeader')}
              </span>
            </Col>

            <Col
              className='d-flex justify-content-lg-center justify-content-xl-end justify-content-end pe-xl-3 padding-right-ipad-landscape'
              lg={4}
              xs={2}
              sm={2}
              md={2}
            />
          </Col>
        </Row>

        <div className='divider' />
        <div className='search-container'>
          <input
            type='list'
            value={this.state.searchText.value}
            onChange={this.handleInputChange}
            placeholder={translate('search.searchPlaceholder')}
          />

        </div>

        <div className='pill-button-container'>
          {[translate('search.answer'),
            translate('search.files'),
            translate('search.calls'),
            translate('search.contacts')].map((e) => this.renderGroupController(e))}
        </div>
        <div className='scroll-view'>
          {this.state.groupedOptions.map((groupOption, groupIndex) => this.renderGroup(groupOption, groupIndex))}
        </div>
      </Sidebar>
    );
  }
}

Search.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
};

Search.defaultProps = {
};

const mapStateToProps = (state) => ({
  workspaceSettings: getWorkspaceSettings(state),
  askAndSearchParams: getAskAndSearchParams(state),
  authToken: getAuthToken(state),
});

// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getWorkspaceSettings,
      getAuthToken,
    },
    dispatch,
  );
}

export default withOrientationChange(
  connect(mapStateToProps, mapDispatchToProps)(Search),
);
